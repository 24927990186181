import axios from "@/libs/axios";

export async function index(query) {
    try {
        const { data } = await axios.get("/api/v1/people", { params: query })
        return { data }
    } catch (error) {

    }
}


export async function show(id) {
    try {
        const { data: { data }
        } = await axios.get(`/api/v1/people/${id}`)
        return { data }
    } catch (error) {
        return { error: error.response.data }
    }
}


export async function store(payload) {
    try {
        const { data: { data } } = await axios.post("/api/v1/people", payload)
        return { data }
    } catch (error) {
        return { error: error.response.data }
    }
}

export async function update(id, payload) {
    try {
        const { data: { data } } = await axios.put(`/api/v1/people/${id}`, payload)
        return { data }
    } catch (error) {
        return { error: error.response.data }
    }
}

export async function destroy(id, payload) {
    try {
        const { data } = await axios.delete(`/api/v1/people/${id}`)
        return { data }
    } catch (error) {
        return { error: error.response.data }
    }
}