import axios from "@/libs/axios";

export async function show(id) {
    try {
        const { data: { data } } = await axios.get(`/api/v1/photos/${id}`)
        return { data }
    } catch ({ response: { data } }) {
        return { error: data }
    }
}


export async function store(payload) {
    try {
        const { data } = await axios.post("/api/v1/photos", payload, { headers: { 'Content-Type': 'multipart/form-data' } })
        return { data }
    } catch ({ response: { data } }) {
        return { error: data }
    }
}


export async function destroy(id) {
    try {
        const result = await axios.delete(`/api/v1/photos/${id}`)
        return result.status < 300;
    } catch (error) {
        return { error }
    }
}

