<template>
  <div>
    <h3>{{ $t("person-photo.manager.title") }}</h3>
    <transition name="fade">
      <b-alert
        show
        variant="warning"
        v-if="photos.filter((p) => p.status).length == 0"
      >
        <div class="alert-body">
          {{ $t("person-photo.manager.cta") }}
        </div>
      </b-alert>
    </transition>
    <div class="container">
      <avatar-uploader
        v-for="photo in photos"
        :key="photo.id"
        :avatar="photo.thumbnail"
        :original="photo.src"
        :editable="editable"
        :loading="photo.loading"
        :group="`person-${personId}`"
        @change="({ file }) => changePhoto(file, photo.id)"
        :delete-confirm="true"
      >
        <template v-if="photo.status" v-slot:footer>
          <photo-status-badge :status="photo.status" />
        </template>
      </avatar-uploader>
    </div>
  </div>
</template>

<script>
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import PersonPhoto from "./PersonPhoto.vue";
import { store, destroy, show } from "@/api/person-photo.api";
import AvatarUploader from "@/components/Person/AvatarUploader.vue";
import PhotoStatusBadge from "@/components/PhotoStatusBadge.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "PersonPhotoManager",
  components: { PersonPhoto, FeatherIcon, AvatarUploader, PhotoStatusBadge },
  props: ["initialPhotos", "personId", "editable"],
  watch: {
    initialPhotos() {
      this.preparePhotoArray();
    },
  },
  data() {
    return {
      selectedPhoto: null,
      file: null,
      photos: [],
      maxPhotos: 3,
      icons: {
        completed: "CheckCircleIcon",
        pending: "ClockIcon",
        failed: "AlertTriangleIcon",
        "no-face": "UserXIcon",
      },
      iconColors: {
        completed: "success",
        pending: "info",
        processing: "info",
        failed: "danger",
        "no-face": "danger",
      },
    };
  },
  methods: {
    clearSelectedPhoto() {
      this.file = null;
      this.selectedPhoto = null;
    },
    async uploadSelectedPhoto(file) {
      const data = new FormData();
      data.append("photo", file);
      data.append("person_id", this.personId);
      const { data: result, error } = await store(data);
      if (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.title,
            icon: "XIcon",
            variant: "danger",
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("common.photo_uploaded"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }

      return result;
    },

    async changePhoto(file, id) {
      const photo = this.photos.find((p) => p.id == id);
      if (!file) {
        photo.id = Math.random();
        photo.thumbnail = null;
        photo.status = null;
        photo.loading = false;
        if (photo.interval) {
          // foto durumunu güncellemek için
          clearInterval(photo.interval);
        }
        destroy(id);
        return;
      }
      photo.loading = true;
      const { data } = await this.uploadSelectedPhoto(file);
      photo.loading = false;

      const inProgress = (status) => {
        return ["pending", "processing"].includes(status);
      };

      if (data) {
        photo.id = data.id;
        photo.thumbnail = data.media.thumbnail;
        photo.status = data.status;

        if (!inProgress(photo.status)) {
          return;
        }

        photo.interval = setInterval(async () => {
          const { data } = await show(photo.id);
          if (photo?.status && photo.status != data.status) {
            photo.status = data.status;

            if (!inProgress(photo.status) && photo.status != "completed") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("person-photo.status." + photo.status),
                  icon: "XIcon",
                  variant: "danger",
                },
              });
            }

            if (!inProgress(photo.status) || photo.status == "completed") {
              clearInterval(photo.interval);
            }

            if (photo.status == "completed") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("person-photo.status.completed"),
                  icon: "CpuIcon",
                  variant: "primary",
                },
              });
            }
          }
        }, 1000);
      }
    },
    preparePhotoArray() {
      this.photos = this.initialPhotos.map((p) => ({
        id: p.id,
        thumbnail: p.media
          ? p.media.thumbnail
          : require("@/assets/images/icons/blank-profile.png"),
        status: p.status,
        has_error: !p.media,
      }));
      for (let index = this.photos.length; index < 3; index++) {
        this.photos.push({
          id: index,
          thumbnail: null,
          status: null,
          loading: false,
        });
      }
    },
  },
  mounted() {
    this.preparePhotoArray();
  },
};
</script>


<style  lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.upload-button {
  width: 30% !important;
  aspect-ratio: 1/1;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 1021px) {
  .upload-button {
    width: 45% !important;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    gap: 1rem 0.5rem;
  }
}

.status-badge {
  cursor: pointer;
  margin: 4px;
  padding-left: 4px;
  padding-right: 4px;
}
</style>