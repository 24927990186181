<template>
  <div class="image-box">
    <b-button
      class="btn-icon rounded-circle btn-sm"
      variant="gradient-danger"
      @click="removePhoto"
    >
      <feather-icon icon="XIcon" />
    </b-button>
    <b-img thumbnail fluid :src="photo.url" />
  </div>
</template>

<script>
import { destroy } from "@/api/person-photo.api";

export default {
  props: ["photo", "personId"],
  methods: {
    async removePhoto() {
      const result = await this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      });

      if (result.value) {
        const result = await destroy(this.photo.id);
        this.$swal({
          icon: result ? "success" : "warning",
          title: "Deleted!",
          text: "Your file has been deleted.",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        this.$emit("deleted", { id: this.photo.id });
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.image-box {
  width: 100% !important;
  max-width: 192px;
  position: relative;
  img {
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  button {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .image-box {
    width: 45% !important;
  }
}
</style>