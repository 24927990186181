<template>
  <feather-icon
    v-b-tooltip.hover="{ variant: color }"
    :class="`status-icon rounded-circle text-white bg-${color}`"
    :icon="icon"
    :variant="color"
    :title="message"
  />
</template>

<script>
export default {
  name: "photo-status-badge",
  props: ["status"],
  computed: {
    icon() {
      return {
        completed: "CheckCircleIcon",
        pending: "ClockIcon",
        processing: "CpuIcon",
        failed: "AlertTriangleIcon",
        "no-face": "UserXIcon",
      }[this.status];
    },
    color() {
      return {
        completed: "success",
        pending: "info",
        processing: "primary",
        failed: "danger",
        "no-face": "danger",
      }[this.status];
    },
    message() {
      return this.$t("person-photo.status." + this.status);
    },
  },
};
</script>

<style>
.status-badge {
  cursor: pointer;
  margin: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.status-icon {
  width: 20px;
  height: 20px;
  padding: 4px;
  margin: 2px;
}
</style>